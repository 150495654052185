<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    :z-index="1100"
    :content-class="`osk-global-dialog ${phoneVerifyDialogSettings?.secondDialog ? 'osk-global-dialog_second' : ''} osk-auth-dialog`"
    @update:model-value="updateModelValue"
  >
    <UikitLoaderInner v-if="socialLoading" />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <WebToAppDownloadAppMobileTop
      v-if="$device.isMobile"
      app-link="https://oskelly.onelink.me/kabM/wgktazb3"
    />

    <WebToAppDownloadAppMobileTop
      v-else
      :promo-text="$t('auth.phoneVerifyDialog.webToApp.promoText', { sale })"
      :subtitle="$t('auth.phoneVerifyDialog.webToApp.subtitle', { promoCode })"
      :with-btn="false"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_registration_login.webp"
          alt="qr_code"
          height="76px"
          width="76px"
        >
      </template>
    </WebToAppDownloadAppMobileTop>

    <UikitTransitionCollapse
      :show="showError"
      :mb="16"
      class="osk-auth-dialog__caption"
    >
      <div class="osk-auth-dialog__error">
        {{ $t('auth.error') }}
      </div>
    </UikitTransitionCollapse>

    <div class="osk-global-dialog__title">
      {{ $t('auth.title') }}
    </div>

    <p class="osk-global-dialog__p">
      {{ PHONE_VERIFICATION_WHATSAPP_FIRST ? $t('auth.sendWa') : $t('auth.sendSms') }}
    </p>

    <UikitForm
      ref="$form"
      class="osk-auth-dialog__form"
      @submit-validate-success="handlePhoneSuccess"
      @submit-validate-error="handleSubmitError"
    >
      <UikitFormPhoneInput
        ref="$phoneInput"
        v-model="phoneNumber"
        name="phoneNumber"
        :label="$t('auth.phoneNumberLabel')"
        :only-countries="onlyCountries"
        required
        rules="phone2"
        hide-star
      />

      <UikitFormErrorMessage name="globalError" />

      <UikitButton
        class="osk-auth-dialog__button"
        full-width
        :loading="loading"
      >
        {{ $t('auth.getCode') }}
      </UikitButton>

      <UikitButton
        type="button"
        color="secondary"
        class="osk-mt-cp"
        full-width
        @click="openChooseAccountDialog"
      >
        {{ $t('auth.loginByEmailAndPassword') }}
      </UikitButton>
    </UikitForm>

    <ul class="osk-auth-dialog__list">
      <li
        v-if="isGoogleReady"
        class="osk-auth-dialog__item"
        @click="handleGoogle"
      >
        <img
          src="~assets/img/google.svg"
          alt="google logo"
          class="osk-auth-dialog__icon"
        >
      </li>

      <li
        v-if="showYandexLink"
        class="osk-auth-dialog__item"
      >
        <span
          :class="{
            'osk-auth-dialog__item-link': true,
          }"
          @click="handleYandex"
        >
          <img
            src="~assets/img/yandex.svg"
            alt="google logo"
            class="osk-auth-dialog__icon"
          >
        </span>
      </li>
    </ul>

    <template #dialogs>
      <AuthSmsDialog
        v-model="authSmsDialog"
        auth
        :formatted-phone="formattedPhone"
        :phone="phoneNumber"
        :only-countries="onlyCountries"
        :token="verificationToken"
        :loading="generateCodePending"
        :error="generateCodeError?.data?.message"
        :z-index="1101"
        @resend="resendSmsCode"
        @close="emits('update:modelValue', false)"
        @register="handleRegister"
      />

      <AuthChooseAccountDialog
        v-model="authChooseAccountDialog"
        :z-index="1101"
        @global-close="handleClose"
      />

      <AuthLoginDialog
        v-model="loginDialog"
        :z-index="1102"
        @global-close="handleClose"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';

import type { TSubmitSuccess } from '~/types/components/form';

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
  (e: 'register'): void,
}>();

const page = 'mobile_authorization';

const loginDialog = ref(false);
const authChooseAccountDialog = ref(false);
const $form = ref();
const showError = ref(false);

const showYandexLink = ref(false);

const config = useRuntimeConfig();
const { savedAccounts, authDialogChapter } = useAccountStore();
const { PHONE_VERIFICATION_WHATSAPP_FIRST } = useExperimentsStore();

const {
  isGoogleReady, SocialNetwork,
  socialLoading, login, socialError,
  parseJwtSocialError,
} = useAuthSocial();

const {
  phoneVerifyDialogSettings,
  authSmsDialog,
  phoneNumber,
  loading,
  $phoneInput,
  getCountries,
  onlyCountries,
  formattedPhone,
  resendSmsCode,
  submitSuccess,
  operation,
  verificationToken,
  generateCodePending,
  generateCodeError,
} = usePhoneVerification();
const { promoCode, sale } = useWebToApp();
const { isDesktop } = useUiBreakpoints();
const { $addEvent } = useNuxtApp();

function handlePhoneSuccess(data: TSubmitSuccess<any>) {
  showError.value = false;

  submitSuccess(data, {
    page,
    action: 'confirm',
    chapter: authDialogChapter.value ?? '',
  });
}

function handleGoogle() {
  showError.value = false;

  login(SocialNetwork.Google, handleRegister);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'google_authorization',
  });
}

function handleYandex() {
  showError.value = false;

  login(SocialNetwork.Yandex, handleRegister);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'yandex_authorization',
  });
}

function handleClose() {
  emits('update:modelValue', false);
}

function openChooseAccountDialog() {
  showError.value = false;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'email_authorization',
  });

  if (!savedAccounts.value?.length) {
    loginDialog.value = true;

    return;
  }

  authChooseAccountDialog.value = true;
}

function handleSubmitError() {
  showError.value = false;
  $form.value.setFieldError('globalError', undefined);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    action: 'confirm',
    chapter: authDialogChapter.value ?? '',
  });
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

function handleRegister() {
  emits('update:modelValue', false);

  setTimeout(() => {
    emits('register');
  }, 300);
}

watch(socialError, (value) => {
  if (value && value.statusCode !== 404) {
    console.error('Error', socialError.value);
    showError.value = true;
  } else {
    showError.value = false;
  }
});

watch(parseJwtSocialError, (value) => {
  if (value) {
    console.error('Error', parseJwtSocialError.value);
  }

  showError.value = !!value;
});

watch(() => props.modelValue, async (value) => {
  if (value) {
    if (window.YaAuthSuggest && config.public.YANDEX_CLIENT_ID) {
      showYandexLink.value = true;
    }

    operation.value = 'AUTH_OR_REGISTER';

    $addEvent(AnalyticsEvents.BlockView, {
      page,
      chapter: authDialogChapter.value,
    });

    await getCountries();

    if ($phoneInput.value) {
      $phoneInput.value.setFocus();
    }
  } else {
    showError.value = false;

    setTimeout(() => {
      phoneNumber.value = '';
    }, 200);
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-dialog {
  .osk-web-to-app-download-app-mobile-top {
    position: absolute!important;;
    top: 48px;
    left: 16px;
    right: 16px;
    z-index: 999!important;

    @include media-query(lg-and-up) {
      top: 88px;
      left: 52px;
      right: 52px;
    }
  }

  &.osk-dialog__body {
    padding-top: 208px;

    @media screen and (max-height: 510px) {
      justify-content: flex-start;
      padding-top: 170px;
    }

    @include media-query(lg-and-up) {
      @media screen and (max-height: 590px) {
        justify-content: flex-start;
        padding-top: 220px;
      }
    }
  }

  &__form {
    padding-top: 12px;
  }

  &__button {
    margin-top: 32px !important;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &__item {
    width: 41px;
    height: 41px;
    overflow: hidden;
    border: 1px solid $grey-3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    cursor: pointer;
    transition: all linear .2s;

    @include media-query(lg-and-up) {
      &:hover {
        background: $grey-4;
        border: 1px solid $grey-4;

        .osk-auth-dialog__item-mail-icon {
          color: $black;
        }
      }
    }
  }

  &__item_mail {
    background: $grey-4;
    border: 0;

    @include media-query(lg-and-up) {
      &:hover {
        background: $grey-4;
        border: 0;
      }
    }
  }

  &__item-mail-icon {
    width: 20px;
    height: 17px;
    color: $grey;
    transition: all linear .2s;
  }

  &__item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__caption {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 52px;

    @include media-query(lg-and-up) {
      left: 52px;
      right: 52px;
      top: 94px;
      margin: 0;
    }
  }

  &__error {
    border-radius: 2px;
    background: $red;
    height: 31px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}
</style>
