<template>
  <nuxt-link
    :to="{ name: 'cart' }"
    no-prefetch
    class="osk-header-cart"
    @click="handleCart"
  >
    <div
      v-if="cartOrders.length"
      class="osk-header-cart__count"
    >
      <span class="osk-desktop-hidden">
        {{ cartOrders.length > 9 ? '9+' : cartOrders.length }}
      </span>

      <span class="osk-mobile-hidden">
        {{ cartOrders.length > 99 ? '99+' : cartOrders.length }}
      </span>
    </div>

    <UikitIcon
      v-if="cartOrders.length"
      name="ShoppingCartFilled"
      size="l"
    />

    <UikitIcon
      v-else
      name="ShoppingCart"
      size="l"
    />
  </nuxt-link>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const { cartOrders } = useCartStore();

function handleCart() {
  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'cart',
  });
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-cart {
  display: flex;
  align-items: center;

  &__count {
    margin-right: $indent-xsmall;
  }
}
</style>
