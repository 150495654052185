<template>
  <div class="osk-header-top-actions">
    <UikitButton
      class="osk-header-top-actions__button osk-header-top-actions__button_concierge"
      size="s"
      :disabled="isMounted ? isConciergePage : false"
      @click="handleConcierge"
    >
      {{ $t('components.header.headerTopActions.concierge') }}
    </UikitButton>

    <UikitButton
      v-if="globalSettings.applicationFeatureFlags?.enableProductPublication"
      outline
      size="s"
      class="osk-header-top-actions__button"
      @click="handleSell"
    >
      {{ $t('components.header.headerTopActions.sell') }}
    </UikitButton>

    <NotificationsGlobal class="osk-header-top-actions__link" />
    <HeaderUserMenu class="osk-header-top-actions__link" />
    <HeaderWishlist class="osk-header-top-actions__link" />
    <HeaderCart class="osk-header-top-actions__link" />
    <HeaderCountry
      v-if="CHANGE_COUNTRY_WEB_EXPERIMENT_ON"
      class="osk-header-top-actions__link"
    />
  </div>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';

const { isAuth, openAuthDialog, globalSettings } = useAccountStore();
const { $addEvent } = useNuxtApp();
const { goToConcierge, isConciergePage } = useConcierge();
const { isMounted } = useUiBreakpoints();
const { CHANGE_COUNTRY_WEB_EXPERIMENT_ON } = useExperimentsStore();

function handleSell() {
  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'sell',
  });

  if (!isAuth.value) {
    openAuthDialog({
      to: '/publication',
      chapter: 'sale_request',
    });
    return;
  }

  navigateTo('/publication');
}

function handleConcierge() {
  goToConcierge();

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'concierge',
  });
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-top-actions {
  display: flex;
  align-items: center;
  margin-left: auto;

  &__button {
    padding: 0 $indent-medium;
    font-weight: $font-weight-medium;
    margin-right: 12.46px;

    &_concierge {
      padding: 0 9px;
      font-weight: 600;
    }
  }

  &__link {
    margin-left: $indent-medium;
    cursor: pointer;

    &_first {
      margin-left: 32px;
    }
  }
}
</style>
