<template>
  <component
    :is="component"
    class="osk-header-wishlist"
    :to="isAuth ? profileLink : undefined"
    no-prefetch
    @click.prevent="handleWishList"
  >
    <div
      v-if="wishlistCount"
      class="osk-header-wishlist__count"
    >
      <span class="osk-desktop-hidden">
        {{ wishlistCount > 9 ? '9+' : wishlistCount }}
      </span>

      <span class="osk-mobile-hidden">
        {{ wishlistCount > 99 ? '99+' : wishlistCount }}
      </span>
    </div>

    <UikitIcon
      v-if="wishlistCount"
      name="HeartFilled"
      size="l"
    />

    <UikitIcon
      v-else
      name="Heart"
      size="l"
    />
  </component>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();

const {
  isAuth, account, openAuthDialog,
} = useAccountStore();

const { count: wishlistCount } = useWishlistStore();

const component = computed(() => {
  if (isAuth.value) return defineNuxtLink({});
  return 'span';
});
const profileLink = computed(() => `/profile/${account.value.id}/wishlist`);

function handleWishList() {
  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'wishlist',
  });

  if (!isAuth.value) {
    openAuthDialog({
      to: () => profileLink.value,
      chapter: 'wishlist',
    });
    return;
  }

  navigateTo(profileLink.value);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-wishlist {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__count {
    margin-right: $indent-xsmall;
  }
}
</style>
